.logo {
  background-color: unset !important;
  border: unset !important;
  border-radius:  unset !important;
}

img.lang {
  border: unset !important;
}

